import React from "react";
import "./ModuleCard.css";

function ModuleCard({ image, alt, title, author }) {
  return (
    <>
      <div className="book--items">
        <div className="book--img">
          <img src={image} width={292} height={445} alt={alt} />
        </div>
        <div style={{justifyContent:'center',alignItems:'center'}}>
        <span className="book--name">{title}</span>
        <span className="book-author">{author}</span>
        </div>
       </div>
    </>
  );
}

export default ModuleCard;
